import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

import { detectMob, useInput, toastMsg } from "@src/Helper"
import { forgotPassword } from "@src/http/UserRepository"

import Button from "@src/components/Button"
import SEO from "@components/seo";

import "./_authentication.scss"

function ForgotPassword() {
  const email = useInput("")
  const id = useInput('forgot-password');
  const [isProceed, setIsProceed] = useState(false)

  function handleFormSubmit() {
    setIsProceed(true)
    forgotPassword(email.value)
      .then(res => {
        toastMsg("Success!", res.data.message, "toast-mafia-success")
        setIsProceed(false)
        setTimeout(() => {
          navigate("/signin")
        }, 3000)
      })
      .catch(err => {
        toastMsg("Error!", err.response.data.message, "toast-mafia-danger")
        setIsProceed(false)
      })
  }

  useEffect(() => {
    if (detectMob()) {
      id.setValue('m-forgot-password');
    }
  }, [])

  return (
    <div id={id.value}>
      <SEO title="Forgot Password" />
      <section className="section-200">
        <div className="container">
          <div
            className="card card-dusty-grey text-center mx-auto"
            style={{ maxWidth: 700 }}
          >
            <div className="heading text-monday-blue mb-2">Forgot Password</div>
            <div className="subcaption mb-4">
              Enter your e-mail address below to reset your password
            </div>

            <form
              onSubmit={e => {
                e.preventDefault()
                handleFormSubmit()
              }}
            >
              <div className="form-group text-left">
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="executivemafia@example.com"
                  onChange={e => {
                    email.setValue(e.target.value)
                  }}
                  required
                />
                <label htmlFor="fullname">
                  <i className="mdi mdi-email" /> Email
                </label>
              </div>

              <Button
                type="submit"
                className="button btn-block button-mafia-primary d-flex"
                isDisabled={email.value === "" || isProceed}
                isLoading={isProceed}
                loadingText="Submitting..."
                normalText="Send Reset Link"
              />
            </form>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ForgotPassword
